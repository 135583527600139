/* eslint react/destructuring-assignment: 0 */
import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { kebabCase } from 'lodash'

import { SEO } from '../components'
import bubbles from '../../content/images/bubbles.svg'

import { LocaleContext } from '../components/Layout'
import ListingNews from '../components/News/ListingNews'
import HeroWithImage from '../components/shared/HeroWithImage'

import * as S from '../components/News/styled'

const Category = ({ pageContext: { category, locale }, data: { categories, content, posts }, location }) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]

  const {
    data: {
      hero_title: heroTitle,
      hero_image: { url: heroUrlImage },
      section_title: sectionTitle,
      description: { html },
    },
  } = content

  return (
    <>
      <SEO
        title={`${i18n.category}: ${category} | ${i18n.defaultTitleAlt}`}
        pathname={location.pathname}
        locale={locale}
      />
      <HeroWithImage src={heroUrlImage} title={heroTitle} />
      <S.Wrapper>
        <S.InnerWrapper>
          <S.Row>
            <S.Col>
              <S.Title>{sectionTitle}</S.Title>
            </S.Col>
          </S.Row>
          <S.Row>
            <S.Bubbles src={bubbles} />
            <S.TextCol>
              <S.Text dangerouslySetInnerHTML={{ __html: html }} />
            </S.TextCol>
          </S.Row>
          <S.NavCategories id="nav">
            <S.Link activeClassName="NavLink__currentPath" to="/news/#nav">
              {i18n.allCategories}
            </S.Link>
            {categories.edges.map(cat => (
              <S.Link
                key={cat.node.data.name}
                activeClassName="NavLink__currentPath"
                to={`/news/${kebabCase(cat.node.uid)}/#nav`}
                partiallyActive="true"
              >
                {cat.node.data.name}
              </S.Link>
            ))}
          </S.NavCategories>
          <ListingNews posts={posts.edges} />
        </S.InnerWrapper>
      </S.Wrapper>
    </>
  )
}

export default Category

Category.propTypes = {
  pageContext: PropTypes.shape({
    category: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    posts: PropTypes.shape({
      edges: PropTypes.array.isRequired,
      totalCount: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query CategoryPage($category: String!, $locale: String!) {
    content: prismicNewsPage(lang: { eq: $locale }) {
      data {
        hero_title
        hero_image {
          url
        }
        section_title
        description {
          html
        }
      }
    }
    categories: allPrismicCategory(filter: { lang: { eq: $locale } }, sort: { fields: [data___name], order: ASC }) {
      totalCount
      edges {
        node {
          uid
          data {
            name
          }
        }
      }
    }
    posts: allPrismicPost(
      sort: { fields: [data___date], order: DESC }
      filter: {
        data: {
          categories: { elemMatch: { category: { document: { elemMatch: { data: { name: { eq: $category } } } } } } }
        }
        lang: { eq: $locale }
      }
    ) {
      totalCount
      edges {
        node {
          uid
          data {
            image {
              localFile {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
            title {
              text
            }
            date(formatString: "DD.MM.YYYY")
            description
            categories {
              category {
                document {
                  data {
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
